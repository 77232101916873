import { useAuth } from "@/store/auth";
import { useStation } from "camera/store/station";

export default function useCameraSettingsUpdater(): Optional<CSTypes.SettingsStore, "xmppLogin"> {
  const { device, updateDevice } = useAuth(({ device, updateDevice }) => ({
    device,
    updateDevice
  }));
  const { xmppLogin } = device.xmppCredentials || {};
  const { icon, color, settings, updateSettings, setColor, setIcon } = useStation(
    ({ icon, color, settings, updateSettings, setColor, setIcon }) => ({
      icon,
      color,
      settings,
      updateSettings,
      setColor,
      setIcon
    })
  );
  const { name } = device;

  return {
    xmppLogin,
    state: {
      icon,
      color,
      name,
      settings
    },
    update({ color, name, settings, icon }) {
      if (icon) setIcon(icon);
      if (color) setColor(color);
      if (name) updateDevice({ name });
      if (settings) updateSettings(settings);
    }
  };
}
