import { useState } from "react";

export default class TooltipManager {
  private static openTimeout: null | NodeJS.Timeout = null;
  private static removeTimeout: null | NodeJS.Timeout = null;

  static isInImidiateOpenningPeriod = false;

  static initiateOpen = (cb: () => void) => {
    this.openTimeout = setTimeout(() => {
      this.isInImidiateOpenningPeriod = true;
      cb();
    }, 700);
  };

  static initiateRemove = () => {
    this.removeTimeout = setTimeout(() => {
      this.isInImidiateOpenningPeriod = false;
    }, 400);
  };

  static stopOpenTimeout = () => {
    if (this.openTimeout) clearTimeout(this.openTimeout);
  };

  static stopRemoveTimeout = () => {
    if (this.removeTimeout) clearTimeout(this.removeTimeout);
  };
}

export const useTooltips = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    TooltipManager.stopRemoveTimeout();

    if (!TooltipManager.isInImidiateOpenningPeriod) {
      TooltipManager.initiateOpen(() => setIsOpen(true));
    } else {
      setIsOpen(true);
    }
  };

  const close = () => {
    TooltipManager.stopOpenTimeout();
    setIsOpen(false);
    TooltipManager.initiateRemove();
  };

  return {
    isOpen,
    open,
    close
  };
};
