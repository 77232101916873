import Peers from "@/modules/Peers";
import { commEmitter, dataSyncEmitter, rtcEmitter } from "@/modules/events/emitter";
import { messages } from "@/modules/communication";
import RtcManager from "@/modules/RtcManager";
import Xmpp from "@/modules/Xmpp";
import { cameraStore } from "camera/store/camera";
import { useStation } from "camera/store/station";
import { stopFaceIfrunning } from "../station/MonitoringManager/utils";
import { getStreamConstraints } from "@/modules/stream/constraints";

export default class CameraRtcManager {
  rtc: RtcManager;

  constructor() {
    this.rtc = new RtcManager({
      isPolite: true,
      getStream: () => cameraStore().stream,
      onPeerConnectionClose: this.handleConnectionStateChange
    });
    useStation.subscribe((state) => state.settings.videoQuality, this.updateStreamConstraints);
    dataSyncEmitter.on("premium-change", this.updateStreamConstraints);
    this.updateStreamConstraints();
  }

  startListening = () => {
    commEmitter.on(messages.WebRTCCandidate.name, this.rtc.handleWebRTCCandidates);
    commEmitter.on(messages.WebRTCCreateAnswer.name, this.rtc.handleAnswer);
    commEmitter.on(messages.WebRTCCreateOffer.name, this.rtc.handleOffer);
    commEmitter.on(messages.DeviceUnpairedMessage.name, this.handleDeviceUnpair);
    dataSyncEmitter.on("camera-was-deleted", this.handleDeviceUnpair);
    rtcEmitter.on("connection-state-change", this.handleConnectionStateChange);
    rtcEmitter.on("signaling-state-change", this.handleConnectionStateChange);
    rtcEmitter.on("media-track", this.handleMediaTrack);
  };

  private handleMediaTrack = (e: RTCTrackEvent, jid: string) => {
    log.rtc("handleMediaTrack fired ", e);
    const remoteStream = e.streams[0];

    remoteStream.onremovetrack = (e) => {
      dataSyncEmitter.emit("remove-remote-track", e.track);
    };

    remoteStream.getTracks().forEach((track) => dataSyncEmitter.emit("add-remote-track", track));
  };

  initializeConnection = async (jid: string) => {
    this.rtc.initializePeer(jid);
    await this.rtc.stream.startSendingStreamTo(jid);
  };

  private handleConnectionStateChange = async (jid: string) => {
    const peer = Peers.get(jid);
    if (peer == null || peer.peerConnection == null) return;
    const { connectionState, signalingState } = peer.peerConnection;
    if (connectionState === "disconnected" || signalingState === "closed") {
      log.rtc("connectionState is 'disconnected' or 'closed', about to close and recreate...", jid);
      peer.peerConnection.close();
      stopFaceIfrunning(jid);
      await this.rtc.recreateConnection(jid, true);
    }
  };

  private updateStreamConstraints = async () => {
    const videoQuality = useStation.getState().settings.videoQuality;
    await this.rtc.stream.setConstraints(getStreamConstraints(videoQuality));
  };

  // Maybe should be in RtcManager
  private handleDeviceUnpair = () => {
    const peers = Peers.getAllAsArray();
    peers.forEach(({ jid }) => {
      this.rtc.destroyPeerConnection(jid);
      stopFaceIfrunning(jid);
      Xmpp.getInstance().sendPresence("sender");
    });
  };

  // destroy = () => {
  //   commEmitter.off(messages.WebRTCCandidate.name, this.rtc.handleWebRTCCandidates);
  //   commEmitter.off(messages.WebRTCCreateAnswer.name, this.rtc.handleAnswer);
  //   commEmitter.off(messages.WebRTCCreateOffer.name, this.rtc.handleOffer);
  //   rtcEmitter.off("connection-state-change", this.handleConnectionStateChange);
  //   rtcEmitter.off("signaling-state-change", this.handleConnectionStateChange);
  //   rtcEmitter.off("media-track", this.handleMediaTrack);
  // };
}
