import { useState } from "react";
import MobileCameraSelect from "./components/MobileCameraSelect";
import DesktopCameraSelect from "./components/DesktopCameraSelect";

type Props = {
  children?: any;
  isOpen?: boolean;
  cameraList: MappedCameraInfo[] | null;
  cameraId: string | null;
  forceDarkMode?: boolean;
  close?: Cb;
  changeCamera: Cb<string, Promise<boolean | void>>;
};

export default function CameraList({
  isOpen,
  children,
  cameraList,
  cameraId,
  forceDarkMode,
  changeCamera,
  close
}: Props) {
  const [loadingCameraId, setLoadingCameraId] = useState<null | string>(null);

  const hasBackCamera = cameraList?.find((c) => c?.facing === "BACK");
  const hasFrontCamera = cameraList?.find((c) => c.facing === "FRONT");

  const CameraSelectComponent = hasBackCamera || hasFrontCamera ? MobileCameraSelect : DesktopCameraSelect;

  async function handleCameraChange(cameraId: string) {
    if (loadingCameraId) {
      log.warn("already changing");
      return;
    }
    setLoadingCameraId(cameraId);
    await changeCamera(cameraId);
    setLoadingCameraId(null);
  }
  if (!cameraList || cameraList.length < 2) return null;

  // use OptionsMenu component
  // log.warn("Use 'OptionsMenu' component instead");

  return (
    <CameraSelectComponent
      isOpen={isOpen}
      close={close}
      cameraId={cameraId}
      cameraList={cameraList}
      changeCamera={handleCameraChange}
      loadingCameraId={loadingCameraId}
      forceDarkMode={forceDarkMode}
    >
      {children}
    </CameraSelectComponent>
  );
}
