import { wait } from "@/utils";
import { useAuth } from "@/store/auth";
import environment from "@/modules/environment";
import { useStation } from "camera/store/station";

const { uploadServerUrl } = environment;
const RETRY_TIMEOUT = 3000;
const FAILURE_THRESHOLD = 4;

export default class Uploader {
  private static failedAttempts = 0;

  private static getTimeout = () => {
    return RETRY_TIMEOUT * this.failedAttempts;
  };

  static upload = async (data: FormData, filename: string): Promise<"OK" | "INIT_SEGMENT_NOT_FOUND"> => {
    try {
      const sessionToken = useAuth.getState().user?.sessionToken;
      const objectId = useStation.getState().objectId;
      if (!sessionToken || !objectId) {
        log.err(`Trying to upload without sessionToken or objectId, dropping ${filename}...:`, sessionToken, objectId);
        return "OK";
      }

      if (!data.has("sessionId")) data.append("sessionId", sessionToken!);
      if (!data.has("cameraStationId")) data.append("cameraStationId", objectId!);

      log.upload(`Trying to upload file ${filename}...`);

      const response = await fetch(uploadServerUrl, {
        method: "POST",
        body: data
      });

      const { code } = (await response.json()) as { code: string; message: string };
      if (code === "OK" || code === "FILE_ALREADY_UPLOADED" || code === "INIT_FILE_ALREADY_UPLOADED") {
        log.upload(`Upload finished for ${filename}`, code);
        this.failedAttempts = 0;
        return "OK";
      } else if (code === "INIT_SEGMENT_NOT_FOUND") return "INIT_SEGMENT_NOT_FOUND";
      else throw Error(code);
    } catch (err: unknown) {
      log.upload(`Upload failed for file ${filename}`, err);
      if (this.failedAttempts >= FAILURE_THRESHOLD) {
        log.err(`Upload failed, attempt ${this.failedAttempts}`, data);
        await wait(RETRY_TIMEOUT * 10);
        return await this.upload(data, filename);
      } else {
        this.failedAttempts += 1;
        log.err(`Upload failed, attempt ${this.failedAttempts}`, data);
        await wait(this.getTimeout());
        return await this.upload(data, filename);
      }
    }
  };
}
