import protoRoot from "../proto-root";

function create() {
  const SupportedFeaturesType = protoRoot.lookupEnum("comm.SupportedFeaturesType");
  const { values } = SupportedFeaturesType;
  const supportedFeatures: number[] = [];

  supportedFeatures.push(values["WEBRTC_PERFECT_NEGOTIATION"]);

  return supportedFeatures;
}

const supportedFeatures = { create };

export default supportedFeatures;
