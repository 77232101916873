import Peers from "../Peers";
import { messages } from "../communication";
import Messenger from "../Messenger";

export default class RtcCandidates {
  private messenger = Messenger.getInstance();

  sendCandidates = (jid: string) => {
    const peer = Peers.get(jid);
    if (peer == null) return;

    const { toSend } = peer.candidates;
    if (toSend.length === 0) return;
    peer.candidates.toSend = [];
    const message = messages.WebRTCCandidate.create(toSend);
    this.messenger.sendAsEnvelope({
      to: jid,
      payload: { message }
    });
  };

  private applyIceCandidate = async ({ candidate, peer }: { candidate: RTCIceCandidateInit; peer: Peer }) => {
    if (peer == null || peer.peerConnection == null) return;
    try {
      log.rtc("About to add ICE candidate", candidate);
      await peer.peerConnection.addIceCandidate(candidate);
    } catch (error) {
      log.err("Add ICE Candidate failed", error, peer.jid);
    }
  };

  handleWebRTCCandidates = async ({ candidates }: { candidates: WebRTCCandidateData }, from: string) => {
    const peer = Peers.get(from);
    if (peer && peer.peerConnection) {
      candidates.forEach((candidate) => {
        const { sdp, ...rest } = candidate;
        const parsedCandidate = { ...rest, candidate: sdp };
        this.applyIceCandidate({
          candidate: parsedCandidate,
          peer
        });
      });
    }
  };
}
