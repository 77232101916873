import protoRoot from "../proto-root";
import DataBuildInformation from "../private/DataBuildInformation";
import SupportedFeatures from "../private/SupportedFeatures";

const name = "startMonitoringRPCRequest";

function create() {
  const StartMonitoringRPCRequest = protoRoot.lookupType("comm.StartMonitoringRPCRequest");

  return {
    payload: StartMonitoringRPCRequest.create({
      versionInfo: DataBuildInformation.create(),
      supportedFeatures: SupportedFeatures.create()
    }),
    name
  };
}

const startMonitoringRPCRequest = {
  create,
  name
};

export default startMonitoringRPCRequest;
