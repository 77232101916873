import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Circle from "@/components/Circle";
import Icon from "@/components/Icon";
import IconButton from "@/components/IconButton";
import { useTransparentize } from "@/theme/utils/transparentize";
import { CameraColors, CameraIcons } from "@/enums/camera";
import AccountIcon from "assets/icons/account.svg?react";
import EditIcon from "assets/icons/edit_outlined.svg?react";
import { t } from "@/utils";
import { textOverflowDots } from "@/theme/utils/style";
import { useAuth } from "@/store/auth";

type DeviceCardProps = {
  name: string;
  icon: CameraIconId;
  color: CameraColorId;
  openDeleteFlow: Cb;
  openCustomize: Cb;
};

export default function DeviceCard({ name, color, icon, openDeleteFlow, openCustomize }: DeviceCardProps) {
  const email = useAuth((s) => s.user?.email);
  const borderColor = useTransparentize("neutral.99", 0.2);

  return (
    <Box w="100%" borderRadius="1rem" py="1.5rem" px="1rem" border="1px solid" borderColor="outline">
      <Flex alignItems="center">
        <Circle
          bg="surface"
          props={{
            border: "1px solid",
            borderColor: borderColor
          }}
        >
          <Icon icon={CameraIcons[icon].icon} fill={CameraColors[color].value} />
        </Circle>

        <Box flexGrow="1" mx="1rem" overflow="hidden">
          <Heading as="h5" {...textOverflowDots}>
            {name}
          </Heading>
          <Flex alignItems="center" columnGap="0.25rem">
            <Icon icon={AccountIcon} fill="on-surface-variant" size="1rem" />
            <Text
              variant="subheader"
              color="on-surface-variant"
              fontSize="16px"
              lineHeight="24px"
              {...textOverflowDots}
            >
              {email}
            </Text>
          </Flex>
        </Box>
        <Flex columnGap="1rem">
          <IconButton
            icon={EditIcon}
            iconSize="24px"
            fill="on-surface"
            onClick={openCustomize}
            tooltip={{ label: t("buttons.edit") }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
