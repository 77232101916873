import { Flex, Text, Spinner } from "@chakra-ui/react";
import { useMonitoringPanelButtonStyle } from "viewer/modules/monitoring/utils/styles";

export default function CameraCircle({
  title,
  isActive,
  Icon,
  isLoading,
  onClick
}: {
  title?: string;
  isActive: boolean;
  isLoading: boolean;
  Icon?: any;
  onClick: () => void;
}) {
  const { getStyleObjAsValues } = useMonitoringPanelButtonStyle();
  const { activeBg, bg, hoverBg } = getStyleObjAsValues(isActive);

  const Content = Icon ? (
    <Icon />
  ) : (
    <Text variant="label" color="neutral.99">
      {title}
    </Text>
  );

  return (
    <Flex
      w="40px"
      h="40px"
      borderRadius="100%"
      cursor="pointer"
      color="neutral.99"
      bg={bg}
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      _hover={{
        bg: hoverBg
      }}
      _active={{
        bg: activeBg
      }}
    >
      {isLoading ? <Spinner color="neutral.99" w="24px" h="24px" /> : Content}
    </Flex>
  );
}
