import { textOverflowDots } from "@/theme/utils/style";
import { MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Menu as ChakraMenu, Flex, Box } from "@chakra-ui/react";
import { useMonitoringIconButtonStyle, useMonitoringPanelButtonStyle } from "viewer/modules/monitoring/utils/styles";

export default function DesktopCameraSelect({
  children,
  isOpen,
  cameraList,
  cameraId,
  forceDarkMode,
  close,
  changeCamera
}: {
  children: any;
  isOpen?: boolean;
  cameraList: MappedCameraInfo[] | null;
  cameraId: string | null;
  forceDarkMode?: boolean;
  close?: Cb;
  changeCamera: (id: string) => void;
}) {
  const buttonStyle = useMonitoringIconButtonStyle();
  const { getStyleObjAsValues } = useMonitoringPanelButtonStyle();
  const { activeBg: darkActiveBg, bg: darkBg, hoverBg: darkHoverBg } = getStyleObjAsValues(false);

  return (
    <ChakraMenu closeOnSelect={false} placement="bottom-end" isOpen={isOpen} onClose={close}>
      {children && <MenuButton>{children}</MenuButton>}
      <MenuList p="0" bg={forceDarkMode ? darkBg : "surface2"}>
        <Flex backdropFilter="blur(16px)" boxShadow="0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px">
          <MenuOptionGroup>
            {cameraList?.map((camera) => {
              const { label, deviceId } = camera;
              const isSelected = camera.deviceId === cameraId;
              return (
                <MenuItemOption
                  key={deviceId}
                  value={deviceId}
                  icon={null}
                  {...(forceDarkMode && {
                    _hover: {
                      bg: darkHoverBg
                    },
                    _active: {
                      bg: darkActiveBg
                    }
                  })}
                >
                  <Flex maxW="240" onClick={() => changeCamera(deviceId)}>
                    {isSelected && (
                      <Box
                        minW="4px"
                        h="1.5rem"
                        mr="0.5rem"
                        borderRadius="7px"
                        bg={forceDarkMode ? "primary.80" : "primary"}
                      />
                    )}
                    <Flex
                      {...textOverflowDots}
                      ml={isSelected ? 0 : "0.75rem"}
                      color={forceDarkMode ? buttonStyle.fill : "onSurface"}
                    >
                      {label || "Unrecognized camera"}
                    </Flex>
                  </Flex>
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </Flex>
      </MenuList>
    </ChakraMenu>
  );
}
