import protoRoot from "../proto-root";
import BatteryStatus from "../private/BatteryStatus";
import CameraAndFlashlightState from "../private/CameraAndFlashlightState";
import CameraInfo from "../private/CameraInfo";
import DataBuildInformation from "../private/DataBuildInformation";
import SupportedFeatures from "../private/SupportedFeatures";

const name = "startMonitoringRPCResponse";

type Payload = Omit<StartMonitoringResponse, "configuration"> & {
  configuration: {
    availableCameras: StartMonitoringResponse["configuration"]["availableCameras"];
  };
};

function create(payload: Payload) {
  const StartMonitoringRPCResponse = protoRoot.lookupType("comm.StartMonitoringRPCResponse");

  const batteryStatus = BatteryStatus.create(payload.batteryStatus);
  const cameraState = CameraAndFlashlightState.create({
    cameraId: payload.cameraState.cameraId || ""
  });
  const configuration = {
    availableCameras: payload.configuration.availableCameras.map((camera) => CameraInfo.create(camera)),
    versionInfo: DataBuildInformation.create(),
    supportedFeatures: SupportedFeatures.create()
  };

  return {
    payload: StartMonitoringRPCResponse.create({
      batteryStatus,
      cameraState,
      configuration
    }),
    name
  };
}

const startMonitoringRPCResponse = {
  create,
  name
};

export default startMonitoringRPCResponse;
