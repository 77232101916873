import { rtcEmitter } from "../events/emitter";
import Messenger from "../Messenger";
import { messages } from "../communication";
import Peers from "../Peers";
import createPeerConnection from "./createPeerConnection";
import RtcCandidates from "./RtcCandidates";
import RtcStream from "./RtcStream";

type DataChannelParams = {
  peerConnection: RTCPeerConnection;
  jid: string;
  label: string;
  id: number;
};
type Constructor = {
  isPolite: boolean;
  getStream: () => MediaStream | null;
  onPeerConnectionClose?: Cb<string, Promise<void>>;
};

const SEND_CANDIDATES_TIMEOUT = 200;

export default class RtcManager {
  private messenger = Messenger.getInstance();
  private candidates: RtcCandidates;
  stream: RtcStream;
  isMakingOffer = false;
  handleWebRTCCandidates: RtcCandidates["handleWebRTCCandidates"];
  onPeerConnectionClose?: Cb<string, Promise<void>>;
  isPolite: boolean;

  constructor({ isPolite, getStream, onPeerConnectionClose }: Constructor) {
    this.candidates = new RtcCandidates();
    this.handleWebRTCCandidates = this.candidates.handleWebRTCCandidates;
    this.onPeerConnectionClose = onPeerConnectionClose;
    this.isPolite = isPolite;

    this.stream = new RtcStream(getStream);
  }

  private initializePeerConnection = (jid: string) => {
    log.rtc("Initializing peer connection for", jid);
    const peerConnection = createPeerConnection();

    peerConnection.ontrack = (e) => {
      log.rtc("ontrack fired", e);
      rtcEmitter.emit("media-track", e, jid);
    };

    peerConnection.onicecandidate = (e) => {
      log.rtc("onicecandidate fired:", e);
      const { candidate } = e;
      const peer = Peers.get(jid);
      if (candidate && peer) {
        if (peer.candidates.toSendTimeoutId) {
          clearTimeout(peer.candidates.toSendTimeoutId);
        }
        peer.candidates.toSend.push({
          sdp: candidate.candidate,
          sdpMLineIndex: candidate.sdpMLineIndex,
          sdpMid: candidate.sdpMid
        });
        peer.candidates.toSendTimeoutId = setTimeout(
          () => this.candidates.sendCandidates(jid),
          SEND_CANDIDATES_TIMEOUT
        );
      }
    };

    peerConnection.onnegotiationneeded = async (e) => {
      log.rtc("onnegotiationneeded fired");
      try {
        this.isMakingOffer = true;
        await peerConnection.setLocalDescription();
        const message = messages.WebRTCCreateOffer.create(peerConnection.localDescription!);
        this.messenger.sendAsEnvelope({ to: jid, payload: { message }, sendBy: "xmpp" });
      } catch (err) {
        log.err("Failed setting local description");
        log.err(err);
      } finally {
        this.isMakingOffer = false;
      }
    };

    peerConnection.onsignalingstatechange = () => {
      log.rtc(`signalingState: ${peerConnection.signalingState}`, jid);
      rtcEmitter.emit("signaling-state-change", jid);
    };

    peerConnection.onicegatheringstatechange = () => {
      const peer = Peers.get(jid);
      log.rtc(`iceGatheringState: ${peerConnection.iceGatheringState}`, jid);
      if (peerConnection.iceGatheringState === "complete") {
        const timeout = peer.candidates.toSendTimeoutId;
        if (timeout) clearTimeout(timeout);
        this.candidates.sendCandidates(jid);
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      log.rtc(`iceConnectionState: ${peerConnection.iceConnectionState}`, jid);
      rtcEmitter.emit("ice-connection-state-change", jid);
      if (peerConnection.iceConnectionState === "failed") {
        peerConnection.restartIce();
        return;
      }
    };

    peerConnection.onconnectionstatechange = () => {
      log.rtc(`connectionState: ${peerConnection.connectionState}`, jid);
      rtcEmitter.emit("connection-state-change", jid);
    };

    log.rtc("peerConnection created", peerConnection);
    return peerConnection;
  };

  private initializeDataChannel = ({ label, id, peerConnection, jid }: DataChannelParams) => {
    const dataChannel = peerConnection.createDataChannel(label, {
      id,
      ordered: true,
      maxRetransmits: 5,
      negotiated: true
    });
    dataChannel.binaryType = "arraybuffer";
    log.rtc(`initializing dataChannel ${dataChannel.label}, jid:`, jid);

    dataChannel.onopen = (e) => {
      log.rtc("dataChannel.onopen fired", e, jid);
      rtcEmitter.emit("data-channel-opened", e, dataChannel, jid);
    };
    dataChannel.onclose = (e) => {
      peerConnection.close();
      log.rtc("dataChannel.onclose fired", e, jid);
      this.onPeerConnectionClose?.(jid);
    };
    return dataChannel;
  };

  initializePeer = (jid: string) => {
    const peerConnection = this.initializePeerConnection(jid);
    const commDataChannel = this.initializeDataChannel({
      peerConnection,
      jid,
      label: "comm",
      id: 1
    });

    const newPeer: Peer = {
      ...Peers.get(jid),
      candidates: {
        toAdd: [],
        toSend: [],
        toSendTimeoutId: null
      },
      jid,
      peerConnection,
      commDataChannel,
      getIsConnectionInErrorState() {
        return (
          this.peerConnection?.connectionState === "closed" ||
          this.peerConnection?.connectionState === "disconnected" ||
          this.peerConnection?.connectionState === "failed"
        );
      }
    };

    this.messenger.addDataChannelMessageHandler(newPeer);
    Peers.add(newPeer);

    log.rtc("peer initialized", newPeer);
    return newPeer;
  };

  recreateConnection = async (jid: string, sendStream?: boolean) => {
    log.rtc("recreating connection for", jid);
    this.destroyPeerConnection(jid);
    const newPeer = this.initializePeer(jid);
    if (sendStream) {
      await this.stream.startSendingStreamTo(newPeer.jid);
    }
    return newPeer;
  };

  private createAndSendAnswerTo = async (peer: Peer) => {
    const { peerConnection, jid } = peer;
    if (peerConnection == null) return;
    log.rtc("about to create answer:", jid);

    try {
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      log.rtc("answer created, about to send it to:", jid);
    } catch (err) {
      log.warn("Answer couldn't be created, sending previous description to:", jid);
      log.err(err);
    }
    const message = messages.WebRTCCreateAnswer.create(peerConnection.localDescription!);

    this.messenger.sendAsEnvelope({
      to: jid,
      payload: { message },
      sendBy: "xmpp"
    });
  };

  handleOffer = async ({ sdp }: { sdp: string }, from: string) => {
    const peer = Peers.get(from);
    if (peer == null || peer.peerConnection == null) return;

    const { peerConnection } = peer;
    const offerCollision = this.isMakingOffer || peerConnection.signalingState !== "stable";
    const ignoreOffer = !this.isPolite && offerCollision;
    if (ignoreOffer) {
      log.rtc("Ignoring offer", peerConnection.signalingState, this.isMakingOffer, this.isPolite);
      return;
    }

    log.rtc("handling offer, setting remote description to offer", from);
    await this.applyOffer({ sdp, peer });
    await this.createAndSendAnswerTo(peer);
  };

  private applyOffer = async ({ sdp, peer }: { sdp: string; peer: Peer }) => {
    if (peer == null || peer.peerConnection == null) return;
    log.rtc("about to apply offer, peerJid", peer.jid);
    try {
      const desc = new RTCSessionDescription({ sdp, type: "offer" });
      await peer.peerConnection.setRemoteDescription(desc);
      log.rtc(`offer applied`, peer.jid);
    } catch (err) {
      log.err("'applyOffer' failed");
      log.err(err);
    }
  };

  handleAnswer = async ({ sdp }: { sdp: string }, from: string) => {
    const peer = Peers.get(from);
    if (peer == null || peer.peerConnection == null) return;
    log.rtc("handling answer, about to apply answer, peerJid", peer.jid);

    if (peer.peerConnection.signalingState === "have-local-offer") {
      await this.applyAnswer({
        sdp,
        peer
      });
    } else {
      log.rtc("cannot applyAnswer, signalingState:", peer.peerConnection.signalingState, from);
    }
  };

  private applyAnswer = async ({ sdp, peer }: { sdp: string; peer: Peer }) => {
    log.rtc("about to apply answer", peer.jid);
    try {
      const desc = new RTCSessionDescription({ sdp, type: "answer" });
      await peer.peerConnection?.setRemoteDescription(desc);
      log.rtc("answer applied", peer.jid);
    } catch (err) {
      log.err("'applyAnswer' failed", err);
      log.err(err);
    }
  };

  destroyPeerConnection = (jid: string) => {
    log.rtc("destroying peer connection", jid);
    const peer = Peers.get(jid);
    if (!peer) return;

    if (peer.candidates.toSendTimeoutId) clearTimeout(peer.candidates.toSendTimeoutId);

    if (peer.commDataChannel) {
      peer.commDataChannel.onopen = () => {};
      peer.commDataChannel.onclose = () => {};
      peer.commDataChannel.onerror = () => {};
    }
    peer.videoTransceiver = null;
    peer.audioTransceiver = null;
    peer.commDataChannel = null;

    if (peer.peerConnection) {
      peer.peerConnection.ontrack = () => {};
      peer.peerConnection.onicecandidate = () => {};
      peer.peerConnection.onnegotiationneeded = () => {};
      peer.peerConnection.onsignalingstatechange = () => {};
      peer.peerConnection.onicegatheringstatechange = () => {};
      peer.peerConnection.oniceconnectionstatechange = () => {};
      peer.peerConnection.onconnectionstatechange = () => {};
      peer.peerConnection.close();
      peer.peerConnection = null;
    }
    Peers.remove(peer.jid);
  };

  destroy() {
    log.rtc("about to destroy RtcManager");
    rtcEmitter.clear();

    Object.values(Peers.getAll()).forEach((peer) => {
      this.destroyPeerConnection(peer.jid);
    });

    this.stream.destroy();
    Peers.reset();
  }
}
