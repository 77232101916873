import { useDevices } from "@/store/devices";
import { debounce } from "@/utils";
import Synchronizer from "../Synchronizer";
import { parseDeviceIq, parseDevice } from "./XmppUtils";

export default class Roster {
  constructor(private getConnection: () => Strophe.Connection) {}
  private synchronizeDevices = debounce(Synchronizer.getInstance().synchronizeDevices, 1000);

  subscribe = (xmppLogin: string) => {
    const connection = this.getConnection();
    log.xmppRoster("Subscribing to roster updates");
    connection.roster.subscribe(xmppLogin);
  };

  unsubscribe = (xmppLogin: string) => {
    const connection = this.getConnection();
    log.xmppRoster("Unsubscribing from roster updates");
    connection.roster.unsubscribe(xmppLogin);
  };

  getRoster = (resolve: (devices: RosterDevice[]) => void) => {
    const connection = this.getConnection();

    connection.roster.get((roster) => {
      log.xmppRoster("received roster ", roster);
      try {
        const parsedRoster = (roster as any as UnparsedDevice[])
          .map(parseDevice)
          .filter((device) => device) as RosterDevice[];

        parsedRoster.forEach((device) => {
          useDevices.getState().updatePairedDevice({
            jid: device.jid,
            group: device.group
          });
        });

        resolve(parsedRoster);
      } catch (err) {
        log.err(err);
        resolve([]);
      }
    });
  };

  onRosterUpdate = (iq: Element) => {
    log.xmpp("received roster update ", iq);
    const subscription = iq.querySelector("item")?.getAttribute("subscription");
    if (subscription === "remove") this.onRosterRemove(iq);
    else this.onRosterSet(iq);
    return true;
  };

  private onRosterSet = (iq: Element) => {
    log.xmppRoster("received roster SET", iq);
    const device = parseDeviceIq(iq.querySelector("item"));
    log.xmppRoster("roster device", device);
    if (!device) return true;
    useDevices.getState().updatePairedDevice(device);
    this.synchronizeDevices();
    return true;
  };

  private onRosterRemove = (iq: Element) => {
    log.xmppRoster("received roster REMOVE", iq);
    const device = parseDeviceIq(iq.querySelector("item"));
    log.xmppRoster("roster device", device);
    if (!device) return true;
    useDevices.getState().removePairedDevice(device.jid);
    return true;
  };
}
