import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalProps,
  ModalContentProps,
  ModalBodyProps,
  TooltipProps,
  Tooltip
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useTooltips } from "@/lib/TooltipManager";

type Props = {
  title?: string | React.ReactNode;
  isOpen: boolean;
  onClose: Cb;
  props?: Omit<ModalProps, "onClose" | "isOpen" | "children">;
  footerContent?: React.ReactNode;
  closeButton?: boolean;
  children?: React.ReactNode;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  disableNavigationEvents?: boolean;
  tooltip?: Omit<TooltipProps, "children">;
};

const modalEscEvent = new Event("modal-esc");

export default function Modal({
  title,
  isOpen,
  onClose,
  footerContent,
  closeButton,
  props,
  children,
  modalContentProps,
  modalBodyProps,
  tooltip,
  // HOTFIXED situation where two modals are opened and one of them has NavigationBar
  disableNavigationEvents
}: Props) {
  const { closeOnEsc = true, closeOnOverlayClick = true } = props || {};
  const [navigationEvents, setNavigationEvents] = useState({ closeOnEsc, closeOnOverlayClick });

  const containerRef = useRef<HTMLDivElement>(null);
  const [bodyStyles, setBodyStyles] = useState<ModalBodyProps>({ overflow: "auto" });
  const tooltipController = useTooltips();

  useEffect(() => {
    const onNavigationEventsUpdate = (u: boolean) => setNavigationEvents({ closeOnEsc: !u, closeOnOverlayClick: !u });
    const onNavigationBarEvent = (u: boolean) => setNavigationEvents((p) => ({ ...p, closeOnEsc: !u }));

    const unsub = [
      dataSyncEmitter.on("flow-transition-start", () => setBodyStyles({ overflow: "hidden" })),
      dataSyncEmitter.on("flow-transition-end", () => setBodyStyles({ overflow: "auto" })),
      ...(disableNavigationEvents
        ? []
        : [
            dataSyncEmitter.on("set-navigation-events-by-navigation-bar", onNavigationBarEvent),
            dataSyncEmitter.on("set-navigation-events", onNavigationEventsUpdate)
          ])
    ];
    return () => unsub.forEach((cb) => cb());
  }, [disableNavigationEvents]);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
      scrollBehavior="inside"
      onEsc={() => !disableNavigationEvents && document.dispatchEvent(modalEscEvent)}
      initialFocusRef={containerRef}
      {...props}
      {...navigationEvents}
    >
      <ModalOverlay />
      <ModalContent minH={484} {...modalContentProps} ref={containerRef}>
        {closeButton && (
          <Tooltip isOpen={tooltipController.isOpen} {...(tooltip ? { gutter: 4, ...tooltip } : { isDisabled: true })}>
            <ModalCloseButton
              onClick={() => {
                tooltipController.close();
              }}
              onMouseEnter={tooltipController.open}
              onMouseLeave={tooltipController.close}
              top="16"
              right="16"
              zIndex={10}
            />
          </Tooltip>
        )}
        {title && <ModalHeader>{title}</ModalHeader>}
        {/* eslint-disable-next-line react/prop-types */}
        <ModalBody {...bodyStyles} {...modalBodyProps}>
          {children}
        </ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
}
