import environment from "@/modules/environment";

// const ONE_MINUTE = 60 * 1000;
export const DISCONNECT_TIMEOUT = 10 * 1000;

export default class XmppUtils {
  status: Strophe.Status | null = null;
  private connectTimeout: NodeJS.Timeout | null = null;
  private disconnectTimeout: NodeJS.Timeout | null = null;
  protected connectResolver: ConnectionResolvers = null;
  protected disconnectResolver: ConnectionResolvers = null;

  protected isReady = () => {
    const isReady = this.status === Strophe.Status.CONNECTED;
    if (!isReady) {
      log.xmpp("Connection is not ready", new Error().stack);
      return false;
    }
    return true;
  };

  private clearConnectTimeout = () => {
    if (this.connectTimeout) {
      clearTimeout(this.connectTimeout);
      this.connectTimeout = null;
    }
  };

  private clearDisconnectTimeout = () => {
    if (this.disconnectTimeout) {
      clearTimeout(this.disconnectTimeout);
      this.disconnectTimeout = null;
    }
  };

  protected createConnectPromise = () => {
    const promise = new Promise((resolve, reject) => {
      this.clearConnectTimeout();
      this.connectTimeout = setTimeout(() => reject("Connect timeout"), DISCONNECT_TIMEOUT);
      this.connectResolver = {
        resolve: (val) => {
          this.clearDisconnectTimeout();
          this.clearConnectTimeout();
          resolve(val);
        },
        reject: (reason) => {
          this.clearConnectTimeout();
          reject(reason);
        }
      };
    });
    return promise;
  };

  protected createDisconnectPromise = () => {
    const promise = new Promise((resolve, reject) => {
      this.clearDisconnectTimeout();
      this.disconnectTimeout = setTimeout(() => reject("Disconnect timeout"), DISCONNECT_TIMEOUT);
      this.disconnectResolver = {
        resolve: (val) => {
          this.clearDisconnectTimeout();
          resolve(val);
        },
        reject: (reason) => {
          this.clearDisconnectTimeout();
          reject(reason);
        }
      };
    });
    return promise;
  };
}

const getFullJid = (jid: string) => {
  return jid + "@" + environment.xmppDomain + "/tm";
};

const parseDeviceIq = (deviceIq: Element | null) => {
  if (!deviceIq) return null;
  try {
    const defaultGroup = JSON.parse(deviceIq.querySelector("group")?.innerHTML as string);
    const jid = deviceIq.getAttribute("jid") as string;
    const deviceName = deviceIq.getAttribute("name") as string;
    const localJidPart = Strophe.getNodeFromJid(jid);
    return {
      jid: localJidPart,
      group: defaultGroup.type,
      name: deviceName
    };
  } catch (err) {
    log.err(err);
    return null;
  }
};

const parseDevice = (device: UnparsedDevice) => {
  try {
    const deviceGroups = device.groups[0].replaceAll("&quot;", `"`);
    const defaultGroup = JSON.parse(deviceGroups);
    const localJidPart = Strophe.getNodeFromJid(device.jid);
    return {
      jid: localJidPart,
      group: defaultGroup.type,
      name: device.name
    };
  } catch (err) {
    log.err(err);
    return null;
  }
};

const getChildText = (e: Element, child: string) => e.querySelector(child)?.innerHTML;

export { getChildText, parseDeviceIq, parseDevice, getFullJid };
