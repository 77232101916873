import protoRoot from "../proto-root";

function getData() {
  return {
    version: __APP_VERSION__,
    build: __APP_VERSION__.split("-")[0].replace(/\./g, ""),
    platform: "browser",
  };
}

function create() {
  const DataBuildInformation = protoRoot.lookupType(
    "comm.DataBuildInformation"
  );

  return DataBuildInformation.create(getData());
}

const dataBuildInformation = { create };

export default dataBuildInformation;
