import { MenuButton, MenuList, Flex, Menu as ChakraMenu, Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FrontCamera from "assets/icons/account_circle_outlined.svg?react";
import { useMonitoringIconButtonStyle } from "viewer/modules/monitoring/utils/styles";
import CameraCircle from "./CameraCircle";

export default function MobileCameraSelect({
  children,
  isOpen,
  cameraList,
  cameraId,
  loadingCameraId,
  close,
  changeCamera
}: {
  children: any;
  isOpen?: boolean;
  loadingCameraId: string | null;
  cameraList: MappedCameraInfo[];
  cameraId: string | null;
  close?: Cb;
  changeCamera: (id: string) => void;
}) {
  const backCameras = cameraList?.filter((c) => c.facing === "BACK");
  const frontCamera = cameraList?.filter((c) => c.facing === "FRONT")[0];
  const { t } = useTranslation();
  const buttonStyle = useMonitoringIconButtonStyle();

  return (
    <ChakraMenu closeOnSelect={false} placement="right" isOpen={isOpen} onClose={close}>
      {children && <MenuButton>{children}</MenuButton>}
      <MenuList p="0" bg={buttonStyle.bg}>
        <Flex
          px="1rem"
          py="0.75rem"
          columnGap="1rem"
          backdropFilter="blur(16px)"
          boxShadow="0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          {frontCamera && (
            <Box borderRight="1px solid rgba(255, 255, 255, 0.2)" pr="1rem" textAlign="center">
              <CameraCircle
                Icon={FrontCamera}
                isLoading={frontCamera.deviceId === loadingCameraId}
                isActive={frontCamera.deviceId === cameraId}
                onClick={() => changeCamera(frontCamera.deviceId)}
              />
              <Text variant="label2" color="neutral.99" mt="2px" lineHeight="16px">
                {t("camera.front")}
              </Text>
            </Box>
          )}
          <Box textAlign="center">
            <Flex columnGap="1rem">
              {backCameras?.map((c, i) => {
                const { zoomLevel, deviceId } = c;
                return zoomLevel ? (
                  <CameraCircle
                    title={`${Math.round(zoomLevel * 10) / 10}x`}
                    isLoading={deviceId === loadingCameraId}
                    key={i}
                    isActive={c.deviceId === cameraId}
                    onClick={() => changeCamera(deviceId)}
                  />
                ) : null;
              })}
            </Flex>
            <Text variant="label2" color="neutral.99" mt="2px" lineHeight="16px">
              {t("camera.backCameras")}
            </Text>
          </Box>
        </Flex>
      </MenuList>
    </ChakraMenu>
  );
}
